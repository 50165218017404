import MissionType from "@shared/enums/Mission"

export const DRAWING_MISSION_TYPES = [
  MissionType.DrawingEachTeam,
  MissionType.DrawingPictionary,
  MissionType.DrawingEveryone,
  MissionType.SpiralDrawing
]

export const GAME_OVER_COMPATIBLE_MISSION_TYPES = [
  ...DRAWING_MISSION_TYPES,
  MissionType.TakePhoto,
  MissionType.PhotoBooth,
  MissionType.Giphy,
  MissionType.Photo,
  MissionType.FreeForm
]

export const MEDIA_MISSION_TYPES = [
  ...DRAWING_MISSION_TYPES,
  MissionType.Photo,
  MissionType.TakePhoto,
  MissionType.PhotoBooth,
  MissionType.Video,
  MissionType.VideoIndividual,
  MissionType.VideoTeam,
  MissionType.Giphy,
  MissionType.URL,
  MissionType.TeamPhoto,
  MissionType.UnconditionalPositiveRegard,
  MissionType.Slides
]
