import {
  DRAWING_MISSION_TYPES,
  GAME_OVER_COMPATIBLE_MISSION_TYPES
} from "./Voting.consts"
import { db, ServerValue } from "@/firebase"
import Collection from "@shared/Collection"
import MissionCollection from "@shared/MissionCollection"
import MissionType from "@shared/enums/Mission"
import * as moment from "moment"
import { mapGetters } from "vuex"

const serializeIcon = string =>
  String(string).replace(/:.*/, "").replace(/ /g, "-").toLowerCase()

const getIcon = type => serializeIcon(`missions/${type}`)

function getMissionPoints(plays, points, isTeamMission) {
  const int = parseInt(points) || 0
  const n = plays.length
  if (isTeamMission === false && n > 0) return parseInt(int / n) || 0

  return int
}

// TODO - simplify

export default {
  beforeDestroy() {
    this.$_VotingMixin_votingRef?.off("value", this.$_VotingMixin_onSnapshot)
  },
  computed: {
    ...mapGetters({
      $_VotingMixin_gameStatus: "gameStatus",
      $_VotingMixin_user: "user",
      $_VotingMixin_gameID: "gameID",
      $_VotingMixin_orgID: "orgID",
      $_VotingMixin_usersArray: "onlineUsersArray"
    }),
    $_VotingMixin_currentOneAtTimePlayIndex() {
      return this.$_VotingMixin_gameStatus?.oneAtTimeVotingIndex ?? 0
    },
    $_VotingMixin_isDrawing() {
      return DRAWING_MISSION_TYPES.includes(this?.$_VotingMixin_missionType)
    },
    $_VotingMixin_votingRef() {
      const gameID = this.$_VotingMixin_gameID
      const orgID = this.$_VotingMixin_orgID
      const missionID = this.$_VotingMixin_currentMissionID

      if (!gameID || !orgID || !missionID) return null

      return db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/votes`)
        .orderByChild("missionID")
        .equalTo(missionID)
    },
    $_VotingMixin_playStep() {
      const { activityId } = this.$_VotingMixin_mission
      if (activityId) {
        return MissionCollection.normalize(this.missions).find(
          mission =>
            mission.activityId === activityId && mission.step === "play"
        )
      }

      return false
    },
    $_VotingMixin_missionList() {
      let array

      const plays = () => {
        if (array) {
          return array
        }
        array = this.$store.getters.plays
        return array
      }

      return MissionCollection.normalize(this.missions)
        .filter(({ behavior, title, id }) => {
          return (
            GAME_OVER_COMPATIBLE_MISSION_TYPES.includes(behavior) &&
            !title.includes("Rules") &&
            plays().some(({ missionID }) => missionID == id)
          )
        })
        .map(({ id, behavior, title }) => ({
          id,
          icon: getIcon(behavior),
          name: title,
          behavior
        }))
    },

    $_VotingMixin_currentLocalMissionID: {
      get() {
        const missionList = this.$_VotingMixin_missionList
        return (
          this.localMissionID ||
          missionList.find(item => item.behavior == MissionType.DrawingEachTeam)
            ?.id ||
          missionList[missionList.length - 1]?.id
        )
      },
      set(value) {
        this.localMissionID = value
      }
    },
    $_VotingMixin_currentMissionID() {
      return this.$_VotingMixin_isGameOver
        ? this.$_VotingMixin_currentLocalMissionID
        : this.$store.getters.currentMission
    },
    $_VotingMixin_realCurrentMission() {
      return this.$store.getters.getCurrentMission
    },
    $_VotingMixin_isGameOver() {
      return this.$_VotingMixin_realCurrentMission?.name.includes("Game Over")
    },
    $_VotingMixin_isVideoBehavior() {
      return [
        MissionType.Video,
        MissionType.VideoIndividual,
        MissionType.VideoTeam
      ].includes(this.$_VotingMixin_missionType)
    },
    $_VotingMixin_isVideoBehaviorEx() {
      return [MissionType.VideoIndividual, MissionType.VideoTeam].includes(
        this.$_VotingMixin_missionType
      )
    },

    $_VotingMixin_missionPlaysArray() {
      if (this.$_VotingMixin_isGameOver) {
        return this.$store.getters.plays.filter(
          ({ missionID }) => missionID == this.$_VotingMixin_currentMissionID
        )
      }
      return this.$store.getters.missionPlays
    },
    $_VotingMixin_voteElementsPre() {
      const users = this.$store.getters["GameUsers/users"] || {}
      const teams = this.$store.getters.chats || {}

      const missionPlays = this.$_VotingMixin_missionPlaysArray
        .map(play => {
          const {
            behavior,
            correct,
            score,
            id,
            missionID,
            userID,
            teamID,
            time
          } = play
          const { firstname, lastname, role } = users[userID] || {}
          const team = teams[teamID] || {}
          return {
            time: parseInt(time) || 0,
            id,
            behavior,
            correct,
            score,
            missionID,
            userID,
            firstname,
            lastname,
            role,
            teamID,
            team: team.name,
            teamIcon: team.icon || "radio_button_unchecked",
            caption: correct?.caption ? String(correct?.caption).trim() : ""
          }
        })
        .sort((a, b) => ("" + a.time).localeCompare(b.time))

      return this.$_VotingMixin_mission?.oneAtTimeVoting
        ? [missionPlays[this.$_VotingMixin_currentOneAtTimePlayIndex]] || []
        : missionPlays || []
    },
    $_VotingMixin_mission() {
      return this.missions?.[this.$_VotingMixin_currentMissionID]
    },
    $_VotingMixin_missionType() {
      return this.$_VotingMixin_mission?.behavior
    },
    $_VotingMixin_isTeamMission() {
      return [
        "Team: Speed Matters",
        "Team: Speed Does Not Matter",
        undefined
      ].includes(this.$_VotingMixin_mission?.playType)
    }
  },
  watch: {
    $_VotingMixin_votingRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.$_VotingMixin_onSnapshot)
        newValue?.on("value", this.$_VotingMixin_onSnapshot)
      },
      immediate: true
    }
  },
  methods: {
    async $_VotingMixin_fetchMissions() {
      const snapshot = await db
        .auxiliary()
        .ref(
          `org/${this.$_VotingMixin_orgID}/game/${this.$_VotingMixin_gameID}/missions`
        )
        .once("value")
      this.missions = snapshot.val() || {}
    },
    $_VotingMixin_onSnapshot(snapshot) {
      this.votes = snapshot.val() || {}
    },
    $_VotingMixin_getAvgRating(votedPlay, vote) {
      const votes = Collection.normalize(this.votes)

      let filteredVotes = []
      if (votes.length) {
        filteredVotes = votedPlay.userID
          ? this.$_VotingMixin_filterVotesByUserID(
              votes,
              votedPlay.userID,
              vote.id
            )
          : this.$_VotingMixin_filterVotesByTeamID(
              votes,
              votedPlay.teamID,
              vote.id
            )
      }

      filteredVotes.push(vote)
      const totalVote = filteredVotes.reduce((sum, { vote }) => sum + vote, 0)
      return totalVote / filteredVotes.length
    },

    $_VotingMixin_filterVotesByUserID(votes, userID, excludeVoteID) {
      return votes.filter(
        ({ id, ratedUserID, missionID }) =>
          ratedUserID === userID &&
          missionID === this.$_VotingMixin_currentMissionID &&
          id !== excludeVoteID
      )
    },
    // uses for play's imported from breadcrub (without userID)
    $_VotingMixin_filterVotesByTeamID(votes, teamID, excludeVoteID) {
      return votes.filter(
        ({ id, teamID: voteTeamID, missionID }) =>
          voteTeamID === teamID &&
          missionID === this.$_VotingMixin_currentMissionID &&
          id !== excludeVoteID
      )
    },
    $_VotingMixin_getRatingScore(play, vote, points) {
      const avg = this.$_VotingMixin_getAvgRating(play, vote)
      const ratio = avg / 5
      return parseInt(points * ratio)
    },

    $_VotingMixin_getTeamMatesIDs(teamID, ratedUserID) {
      const ids = this.$_VotingMixin_usersArray
        .filter(user => user.teamID === teamID)
        .map(({ id }) => id)

      if (ids.indexOf(ratedUserID) === -1) {
        ids.push(ratedUserID)
      }
      return ids
    },

    $_VotingMixin_findCurrentVote(play) {
      const { id: _playID } = play
      const votes = this.votes

      if (votes === undefined || votes === null) {
        return null
      }

      const currentMissionID = this.$_VotingMixin_currentMissionID

      for (const voteID in votes) {
        const vote = votes[voteID]
        const { votingUserID, missionID, playID } = vote
        if (
          playID === _playID &&
          votingUserID === this.$_VotingMixin_user.id &&
          missionID === currentMissionID
        ) {
          return vote
        }
      }

      return null
    },
    $_VotingMixin_rated(rate, votedPlay) {
      const database = db.auxiliary()
      const path = `org/${this.$_VotingMixin_orgID}/game/${this.$_VotingMixin_gameID}`
      const foundVote = this.$_VotingMixin_findCurrentVote(votedPlay)
      // for performance reasons, we want don't want to refference through this context
      const currentMission = this.$_VotingMixin_mission

      const foundPlay = this.$store.getters.missionPlays.find(
        ({ id }) => id === votedPlay.id
      )
      const updates = {}
      const sTime = new Date()
      const teamID = votedPlay.teamID
      const activityPoints = this.$_VotingMixin_playStep?.points
      const currentMissionPoints = activityPoints || currentMission.points
      const points = getMissionPoints(
        this.$store.getters.missionPlays,
        currentMissionPoints,
        this.$_VotingMixin_isTeamMission
      )

      const { activityId } = this.$_VotingMixin_mission

      const vote = {
        id: foundVote?.id ?? database.ref(`${path}/votes`).push().key,
        teamID,
        ratedUserID: votedPlay.userID || null,
        votingUserID: this.$_VotingMixin_user.id,
        missionID: this.$_VotingMixin_currentMissionID,
        activityId: activityId ?? null,
        mission: currentMission.name,
        points,
        time: moment(sTime).unix(),
        show: false,
        vote: rate,
        playID: foundPlay.id
      }

      const score = this.$_VotingMixin_getRatingScore(votedPlay, vote, points)

      const teamMatesIDs = this.$_VotingMixin_getTeamMatesIDs(
        teamID,
        votedPlay.userID
      )

      const oldScore = foundPlay.score ?? 0

      const clientID = this.$store.getters["auth/clientID"]

      updates[`${path}/votes/${vote.id}`] = vote
      updates[`${path}/play/${foundPlay.id}/score`] = score
      updates[`client/${clientID}/play/${foundPlay.id}/score`] = score
      updates[`${path}/teams/${teamID}/totalScore`] = ServerValue.increment(
        score - oldScore
      )
      for (const userID of teamMatesIDs) {
        // TODO: is it correct, all time to increment userscore? logic the same as in setScore
        updates[`client/${clientID}/userscore/${userID}`] =
          ServerValue.increment(score)
      }

      db.auxiliary().ref().update(updates)
    }
  }
}
